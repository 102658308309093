var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"rules"},[_c('b-form',{ref:"report-link"},[_c('b-card',{staticClass:"card-payment",attrs:{"id":"#new-team","border-variant":_vm.formVariant,"no-body":""}},[_c('b-card-body',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('TEAM_NAME'),"label-for":"id-teamname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('TEAM_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-teamname","disabled":_vm.competition.gametype===2,"maxlength":"35","placeholder":_vm.$t('TEAM_NAME'),"state":errors.length > 0 ? false:null},model:{value:(_vm.group.groupname),callback:function ($$v) {_vm.$set(_vm.group, "groupname", $$v)},expression:"group.groupname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('FIRST_NAME'),"label-for":"id-firstname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('FIRST_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-firstname","maxlength":"45","placeholder":_vm.$t('FIRST_NAME'),"state":errors.length > 0 ? false:null},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('LAST_NAME'),"label-for":"id-lastname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LAST_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-lastname","maxlength":"45","placeholder":_vm.$t('LAST_NAME'),"state":errors.length > 0 ? false:null},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('EMAIL_ADDRESS'),"label-for":"id-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('EMAIL_ADDRESS'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-email","maxlength":"60","placeholder":_vm.$t('EMAIL_ADDRESS'),"state":errors.length > 0 ? false:null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('PHONE_NUMBER'),"label-for":"id-phonenumber"}},[_c('validation-provider',{attrs:{"name":_vm.$t('PHONE_NUMBER'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"default-country-code":_vm.phonePayload.countryCallingCode},on:{"update":_vm.handlePhoneNumberInput},model:{value:(_vm.user.phonenumber),callback:function ($$v) {_vm.$set(_vm.user, "phonenumber", $$v)},expression:"user.phonenumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"8"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":_vm.formVariant},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.RESET'))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }