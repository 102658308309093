<template>
  <!-- form -->
  <validation-observer ref="rules">
    <b-form ref="report-link">
      <b-card
        id="#new-team"
        :border-variant="formVariant"
        no-body
        class="card-payment"
      >
        <b-card-body>
          <!-- form -->
          <b-form>
            <b-row>

              <b-col cols="12">
                <b-form-group
                  :label="$t('TEAM_NAME')"
                  label-for="id-teamname"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('TEAM_NAME')"
                    rules="required"
                  >
                    <b-form-input
                      id="id-teamname"
                      v-model="group.groupname"
                      :disabled="competition.gametype===2"
                      maxlength="35"
                      :placeholder="$t('TEAM_NAME')"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  :label="$t('FIRST_NAME')"
                  label-for="id-firstname"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('FIRST_NAME')"
                    rules="required"
                  >
                    <b-form-input
                      id="id-firstname"
                      v-model="user.firstname"
                      maxlength="45"
                      :placeholder="$t('FIRST_NAME')"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  :label="$t('LAST_NAME')"
                  label-for="id-lastname"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('LAST_NAME')"
                    rules="required"
                  >
                    <b-form-input
                      id="id-lastname"
                      v-model="user.lastname"
                      maxlength="45"
                      :placeholder="$t('LAST_NAME')"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  :label="$t('EMAIL_ADDRESS')"
                  label-for="id-email"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('EMAIL_ADDRESS')"
                    rules="required|email"
                  >
                    <b-form-input
                      id="id-email"
                      v-model="user.email"
                      maxlength="60"
                      :placeholder="$t('EMAIL_ADDRESS')"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="12"
                md="8"
              >
                <b-form-group
                  :label="$t('PHONE_NUMBER')"
                  label-for="id-phonenumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('PHONE_NUMBER')"
                    rules="required"
                  >
                    <VuePhoneNumberInput
                      v-model="user.phonenumber"
                      :default-country-code="phonePayload.countryCallingCode"
                      @update="handlePhoneNumberInput"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="12"
                md="8"
              >
                <b-button
                  :variant="formVariant"
                  class="mr-50"
                  @click.prevent="submitForm"
                >
                  {{ buttonText }}
                </b-button>
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  @click.prevent="resetForm"
                >
                  {{ $t('BUTTON.RESET') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <!--/ form -->
        </b-card-body>
      </b-card>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol,
  BFormGroup, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import VuePhoneNumberInput from 'vue-phone-number-input'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    VuePhoneNumberInput,
  },
  directives: {
    Ripple,
  },
  props: {
    competition: {
      type: Object,
      default: () => {},
    },
    competitionGroup: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      group: {
        groupID: '0',
        groupname: '',
        competitionID: '',
      },
      user: {
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        country_code: constants.DEFULT_COUNTRY_CODE.DIALING_CODE,
      },
      phonePayload: {
        countryCode: '',
        isValid: false,
        phoneNumber: '',
        countryCallingCode: constants.DEFULT_COUNTRY_CODE.ISO_COUNTRY_CODE,
        formattedNumber: '',
        nationalNumber: '',
        formatInternational: '',
        formatNational: '',
        uri: '',
        e164: '',
      },
      buttonText: constants.BUTTON_TEXT.CREATED_JOIN_NEW_TEAM,
      formVariant: 'primary',
      email,
      required,
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
    }
  },
  watch: {
    competitionGroup: {
      handler() {
        if (this.competitionGroup) {
          this.group.groupID = this.competitionGroup.groupID
          this.group.groupname = this.competitionGroup.groupname
          this.buttonText = `Join ${this.group.groupname}`
          this.formVariant = this.competitionGroup.variant
        }
      },
    },
  },
  mounted() {
    if (this.competitionGroup.groupID) {
      this.group.groupID = this.competitionGroup.groupID
      this.group.groupname = this.competitionGroup.groupname
      this.buttonText = `Join ${this.group.groupname}`
      this.formVariant = this.competitionGroup.variant
    }
  },
  methods: {
    ...mapActions('onboarding', ['createOnboardingUser']),
    submitForm() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.group.user = this.user
          this.group.competitionID = this.competition.encrypt_id
          if (this.phonePayload.nationalNumber) {
            this.group.user.phonenumber = this.phonePayload.nationalNumber
            this.group.user.country_code = `+${this.phonePayload.countryCallingCode}`
          }
          this.saveOnboardingNewGroup()
        }
      })
    },
    handlePhoneNumberInput(payload) {
      // Access the detailed payload from the @input event
      this.phonePayload = payload
    },
    saveOnboardingNewGroup() {
      this.createOnboardingUser(this.group).then(response => {
        if (response) {
          this.group = ''
          this.user = ''
          const id = this.competition.encrypt_id
          this.successMessage('Congratulations! The player has been successfully created!')
          this.$router.push({
            name: 'onboarding-complete-id',
            params: { id },
          })
        }
      }).catch(error => {
        if (error.response.data.data.status === 'failed') {
          this.errorMessage(error.response.data.data.message.toString())
        }
      })
    },
    resetForm() {
      this.buttonText = constants.BUTTON_TEXT.CREATED_JOIN_NEW_TEAM
      this.group.groupID = 0
      this.group.groupname = ''
      this.formVariant = 'primary'
    },
  },
}
</script>
<style lang="scss">
@import '~vue-phone-number-input/dist/vue-phone-number-input.css';
</style>
